import React, { useEffect, useState } from 'react';
import Button from '../../components/button';
import Text from '../../components/text';
import Card from '../../components/card';
import styles from './mandate.module.css';
import MandateCards from './components/MandateCards';
import TextInput from '../../components/textInput';
// import { Icon } from '../../components/icons';
import TwoTabsButton from './components/TwoTabsButton';
import SingleMandateTable from './components/SingleMandateTable';
import {
  combineClassName,
  daysItem,
  getDateFilter,
} from '../../utils/utilsMethods';
import { useLocation, useNavigate } from 'react-router-dom';
import { BULK_MANDATE, SINGLE_MANDATE } from '../../utils/constants';
import { CREATE_MANDATE } from '../../routes/navigations';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectMandateState,
  setCreateMandateData,
  setIsBulk,
  setMandateStat,
} from '../../redux/features/mandateSlice';
import api from '../../network/api';
import BulkMandateContainer from './BulkMandateContainer';
import CustomDropdown, { IItemProps } from '../../components/customDropdown';
import useFetchMandate from '../../hooks/useFetchMandate';
import useRouteQuery from '../../hooks/useRouteQuery';

const Mandate = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(selectMandateState);
  const { setURLQuery, searchParams } = useRouteQuery();
  const dateLabel = searchParams.get('dateLabel');
  const defaultFilterDate = daysItem.find((day) => day.label === dateLabel);
  const { filterMandate: dateFilter, handleMandateFilter } = useFetchMandate(
    dateLabel!
  );
  const filterMandate = defaultFilterDate || dateFilter;
  const [searchString, setSearchString] = useState(searchParams.get('search'));

  const { state } = useLocation();
  const navigate = useNavigate();
  const mandateStat = useAppSelector(selectMandateState).mandateStat;

  useEffect(() => {
    getMandateAnalytics(filterMandate);
  }, [filterMandate]);

  const [selectedBtn, setSelectedBtn] = useState(
    state?.view ? state?.view : SINGLE_MANDATE
  );

  const changeTab = (tab: string) => {
    setSelectedBtn(tab);
    dispatch(setIsBulk(tab !== SINGLE_MANDATE ? true : false));
    setURLQuery({ queryType: tab, search: '' });
    setSearchString('');
  };

  const updateUrl = (dateLabel: string) => {
    const newState = { dateLabel };
    setURLQuery(newState);
  };

  async function getMandateAnalytics(date: IItemProps) {
    try {
      const filterResult = getDateFilter(date);
      const response = await api.getMandateAnalytics({
        startDate: filterResult.fromDate,
        endDate: filterResult.toDate,
      });

      dispatch(
        setMandateStat({
          success: response.data.piechart.success,
          pending: response.data.piechart.pending,
          failed: response.data.piechart.failed,
        })
      );
    } catch (error: unknown) {
      // const err = error as IError;
    }
  }

  const debounce = (delay = 1000) => {
    let timeout: string | number | NodeJS.Timeout | null | undefined = null;
    return (text: string) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        handleSearchMandate(text);
      }, delay);
    };
  };

  const handleSearchMandate = async function (searchTerm: string) {
    setSearchString(searchTerm);
    if (selectedBtn === BULK_MANDATE) {
      dispatch(setIsBulk(false));
      setSelectedBtn(SINGLE_MANDATE);
      setURLQuery({ queryType: SINGLE_MANDATE, search: searchTerm });
    } else {
      setURLQuery({ search: searchTerm });
    }
  };

  const debounceFunction = debounce();

  function updateSearchField(term: string) {
    debounceFunction(term);
  }

  const totalMandate =
    Number(mandateStat?.success) + Number(mandateStat?.failed) || '--';

  return (
    <main className="container">
      <header className="d-flex flex-wrap pt-4 pb-4 justify-content-between align-items-center flex-wrap gap-2 gap-lg-0">
        <div>
          <Text
            text={'Mandates'}
            size={6}
            bold
            color="var(--vfd-main-body-text)"
          />
          <Text
            text={'Overview of all your mandates'}
            size={3}
            color={'var(--vfd-main-body-text)'}
          />
        </div>
        <div className="d-flex mt-3 mt-md-0 gap-2 align-items-center">
          <Button
            label="Create New Mandate"
            onClick={() => {
              dispatch(setCreateMandateData(null));
              navigate(CREATE_MANDATE);
            }}
            type="primary"
          />
        </div>
      </header>

      <div className="row">
        <div className="col-lg-12">
          <Card
            className={`${styles.mandate_card} ${styles.withBorder}  gap-md-5`}
          >
            <MandateCards
              title="Total Mandate"
              number={!loading ? String(totalMandate) : '--'}
            />
            <MandateCards
              title="Successful Mandate"
              number={!loading ? mandateStat?.success || '--' : '--'}
            />
            <MandateCards
              title="Failed Mandate"
              number={!loading ? mandateStat?.failed || '--' : '--'}
            />
          </Card>

          <Card
            className={combineClassName(
              styles.mandate_table,
              selectedBtn === SINGLE_MANDATE
                ? styles.single_mandate
                : styles.bulk_mandate,
              `flex-column shadow`
            )}
          >
            <div className="px-4 pt-4 pb-4 d-flex w-100 flex-column flex-md-row flex-wrap justify-content-center justify-content-md-between">
              <TwoTabsButton
                changeTab={changeTab}
                leftBtn={SINGLE_MANDATE}
                rightBtn={BULK_MANDATE}
                activeTab={selectedBtn}
              />

              <div className="d-flex justify-content-md-between mt-3 mt-md-0 gap-md-4">
                <TextInput
                  label=""
                  placeholder="Search using reference"
                  id="global-search"
                  name="search_0101"
                  key={selectedBtn}
                  type="search"
                  defaultValue={searchString || ''}
                  onChange={(_, value) => {
                    updateSearchField(value.toLowerCase());
                  }}
                  // errorText={validationResult.email}
                  // valid={!validationResult.email}
                  // isRequired
                />

                <CustomDropdown
                  id="filterBy"
                  showIcon={false}
                  items={daysItem}
                  selectedOption={filterMandate}
                  showSelectedLabel
                  handleSelect={(item) => {
                    updateUrl(item.label);
                    handleMandateFilter(item);
                  }}
                  defaultLabel="Filter by:"
                  className="d-none d-md-flex"
                />
              </div>
            </div>

            {selectedBtn === SINGLE_MANDATE && <SingleMandateTable />}

            {selectedBtn === BULK_MANDATE && <BulkMandateContainer />}
          </Card>
        </div>
      </div>
    </main>
  );
};

export default Mandate;
