import React, { FC } from 'react';
import Button from '../button';
import { useNavigate } from 'react-router';
import { Icon } from '../icons';

const BackButton: FC<{ id?: string }> = ({ id }) => {
  const navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }
  return (
    <div className="pt-4 pb-4 d-flex justify-content-start">
      <Button
        id={id}
        onClick={handleClick}
        style={{
          borderRadius: 6,
          border: '1px solid var(--Gray-300, #D0D5DD)',
          background: 'var(--Base-White, #FFF)',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          fontWeight: '700',
          height: 36,
        }}
        type={'outline'}
        label={
          <span>
            <Icon name="arrow_left" />
            &nbsp; Back
          </span>
        }
      />
    </div>
  );
};

export default BackButton;
