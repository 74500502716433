import React, { FC } from 'react';
import Text from '../text';
import TextInput from '../textInput';
import CustomDropdown, { IItemProps } from '../customDropdown';
import { daysItem } from '../../utils/utilsMethods';

const PageTitle: FC<{
  title?: string;
  description?: string;
  hideSearch?: boolean;
  hideFilter?: boolean;
  selectedFilter?: IItemProps;
  handleSelectFilter?: (val: IItemProps) => void;
}> = ({
  title = 'Dashboard',
  description,
  hideSearch = false,
  hideFilter = false,
  selectedFilter,
  handleSelectFilter,
}) => {
  return (
    <div className="pt-4 pb-4">
      <div className="d-flex justify-content-between align-items-center flex-wrap gap-2 gap-lg-0">
        <div>
          <Text text={title} size={6} bold color="var(--vfd-main-body-text)" />
          {description && (
            <Text
              text={description}
              size={1}
              color={'var(--vfd-main-body-text)'}
            />
          )}
        </div>
        <div className="d-flex gap-2 align-items-center">
          {!hideSearch && (
            <div>
              <TextInput
                label="Email"
                placeholder="Type e.g Transactions"
                id="global-search"
                name="search"
                type="search"
                onChange={() => console.log('')}
                // errorText={validationResult.email}
                // valid={!validationResult.email}
                // isRequired
              />
            </div>
          )}

          {!hideFilter && (
            <CustomDropdown
              id={`${title}TimeRange`}
              showIcon={false}
              items={daysItem}
              selectedOption={selectedFilter || daysItem[0]}
              showSelectedLabel
              handleSelect={(item) =>
                handleSelectFilter ? handleSelectFilter(item) : null
              }
              defaultLabel="Filter by:"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
