export const SINGLE_MANDATE = 'Single';
export const BULK_MANDATE = 'Bulk';
export const MANDATE_DETAILS = 'Mandate Details';
export const TRANSACTION_HISTORY = 'Transaction History ';

export const MAX_NUMBER_OF_FIELDS_ON_CREATE_BULK_MANDATE_LIST = 10;

export const storageKey = {
  AUTH_TOKEN: 'AUTH_TOKEN',
  USER_INFO: 'USER_INFO',
};

export const MODAL_TYPES = {
  TRIGGER_DEBIT_MODAL: 'TRIGGER_DEBIT_MODAL',
  TRIGGER_DEBIT_SUCCESS_MODAL: 'TRIGGER_DEBIT_SUCCESS_MODAL',
  TRANSACTION_RECEIPT: 'TRANSACTION_RECEIPT',
  EDIT_TRANSACTION_MODAL: 'EDIT_TRANSACTION_MODAL',
  SUBMIT_BATCH_MODAL: 'SUBMIT_BATCH_MODAL',
  CREATE_RECEIVER_ACCOUNT: 'CREATE_RECEIVER_ACCOUNT',
  CREATE_BILLING_ACCOUNT: 'CREATE_BILLING_ACCOUNT',
};

export const mandateFrequency = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Daily', value: 'daily' },
];
