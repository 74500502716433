/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../components/button';
import Card from '../../../components/card';
import { Icon } from '../../../components/icons';
import Text from '../../../components/text';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useToast from '../../../hooks/useToast';
import { IError } from '../../../interface/network';
import api from '../../../network/api';
import {
  selectGlobalState,
  setBankList,
  setCompanyLogo,
} from '../../../redux/features/globalSlice';
import { openModal } from '../../../redux/features/modalSlice';
import { MODAL_TYPES } from '../../../utils/constants';
import {
  base64PreferredPrefix,
  combineClassName,
  removeBase64Prefix,
} from '../../../utils/utilsMethods';
import styles from '../settings.module.css';

interface IProps {
  title: string;
  text: string;
  textColor?: string;
}

const Column = ({
  title,
  text,
  textColor = 'var(--vfd-main-body-text)',
}: IProps) => {
  return (
    <div className="gap-3">
      <Text text={title} size={2} color={'var(--vfd-main-body-text)'} />
      <Text text={text} bold size={3} className="mt-2 mb-2" color={textColor} />
    </div>
  );
};

const Profile = () => {
  const MAX_SIZE = 2000000;
  const globalState = useAppSelector(selectGlobalState);
  const dispatch = useAppDispatch();
  const toaster = useToast();
  const [base64String, setBase64String] = useState<string | ArrayBuffer | null>(
    ''
  );

  function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target?.files;
    if (files?.length) {
      const file = files[0];
      if (file.size > MAX_SIZE) {
        toaster.error('Image should not be more than 2MB');
        return;
      }
      if (file.type !== 'image/png') {
        toaster.error('Image should be in png format');
        return;
      }
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64 = e.target?.result;
        if (base64) setBase64String(removeBase64Prefix(base64 as string));
      };

      reader.readAsDataURL(file);
    }
  }

  const handleTriggerModal = (modalType: keyof typeof MODAL_TYPES) => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: modalType,
        modalProps: {
          showHeader: false,
        },
      })
    );
  };
  async function getBankList() {
    try {
      const response = await api.getBanks();
      dispatch(setBankList(response.data.bank));

      dispatch(setBankList(response.data.bank));
    } catch (error) {
      const err = error as IError;
      toast.error(err.message || '');
    }
  }

  useEffect(() => {
    if (!globalState?.bankList?.length) {
      getBankList();
    }
  }, []);

  useEffect(() => {
    if (base64String) {
      toast.loading('Upload in progress', { position: 'bottom-left' });
      api
        .uploadLogo({ file: base64String as string })
        .then(() => {
          toast.dismiss();
          toaster.success('Upload successful');
          dispatch(setCompanyLogo(base64String as string));
        })
        .catch((err) => {
          console.error(err);
          toast.dismiss();
          toaster.error('Upload failed');
          setBase64String('');
        });
    }
  }, [base64String]);
  return (
    <div className="my-4 py-4 px-2">
      <div className="mb-5 d-flex flex-column flex-md-row">
        <div
          className={`d-flex flex-column align-items-center pb-2 pb-md-0 justify-content-center px-4 ${styles.profile_image}`}
        >
          <div
            style={{
              height: '7rem',
              width: '7rem',
              borderRadius: '50%',
            }}
            className="mb-3 d-flex justify-content-center align-items-center overflow-hidden"
          >
            <img
              src={
                base64String
                  ? ((base64PreferredPrefix + base64String) as string)
                  : globalState.companyLogo
                    ? base64PreferredPrefix + globalState.companyLogo
                    : 'no_user1.png'
              }
              style={{ objectFit: 'cover' }}
              width={100}
              height={100}
              alt="profile image"
            />
          </div>

          <div
            className="d-flex gap-1 position-relative w-100 justify-content-center"
            style={{ cursor: 'pointer' }}
          >
            <Icon name="camera_icon" />
            <Text
              text={'Update Logo'}
              size={3}
              style={{ cursor: 'pointer' }}
              bold
              color="var(--blue-600)"
            />
            <input
              type="file"
              style={{ cursor: 'pointer' }}
              className="opacity-0 w-100 position-absolute left-0"
              onChange={handleFileUpload}
              accept="image/png"
            />
          </div>
        </div>

        <div className="d-flex align-items-start gap-2 justify-content-center ps-md-3 flex-column mt-3 mt-md-0">
          <Text
            text={globalState.receiverName ?? ''}
            size={3}
            bold
            color="var(--vfd-main-body-text)"
          />
          <Text text={'Admin'} size={2} color="var(--vfd-main-body-text)" />
        </div>
      </div>

      <Card className={combineClassName(styles.profileCard, 'p-4  border-0')}>
        <div className="d-flex  justify-content-between align-items-center">
          <Text
            text={'Business Information'}
            size={4}
            bold
            color="var(--vfd-main-body-text)"
            className=""
          />
        </div>

        <div
          className={`d-flex flex-wrap flex-column flex-md-row justify-content-between gap-3 mt-3 mt-md-5 pb-3 ${styles.userDetailsWidth}`}
        >
          <Column
            title="Business name"
            text={globalState.receiverName ?? '-'}
          />
          <Column text={globalState.email ?? '-'} title="Email" />
          {globalState.receiverAccountNo ? (
            <Column
              text={globalState.receiverAccountNo ?? '-'}
              title="Receiver Account Number"
            />
          ) : (
            <Button
              label="Add receiver account number"
              onClick={() =>
                handleTriggerModal(
                  MODAL_TYPES.CREATE_RECEIVER_ACCOUNT as keyof typeof MODAL_TYPES
                )
              }
              type="primary"
            />
          )}
          {globalState.receiverBank && (
            <Column
              text={
                globalState.bankList?.find(
                  (bank) => bank.code === globalState.receiverBank
                )?.name ?? '-'
              }
              title="Receiver Bank"
            />
          )}
          {globalState.billingAccountNo ? (
            <Column
              text={globalState.billingAccountNo ?? '-'}
              title="Billing Account Number"
            />
          ) : (
            <Button
              onClick={() =>
                handleTriggerModal(
                  MODAL_TYPES.CREATE_BILLING_ACCOUNT as keyof typeof MODAL_TYPES
                )
              }
              label="Add billing account number"
              type="outline"
            />
          )}
        </div>
        <div className="w-md-75 w-100 d-flex flex-wrap justify-content-between mt-md-3 pt-md-3">
          <Column text={'BaaS@vfdtech.ng'} title="Support email" />
        </div>
      </Card>
    </div>
  );
};

export default Profile;
