import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import { IItemProps } from '../components/customDropdown';
import moment from 'moment';
import { frequencyObj } from '../components/Modals/EditMandateModal';
import { frequencyType } from '../interface/mandate';
export const combineClassName = (...args: Array<string | undefined | null>) => {
  return args.join(' ');
};

export const daysItem = [
  { label: 'Today', id: uuid() },
  { label: 'Yesterday', id: uuid() },
  { label: '30 days', id: uuid() },
  { label: '3 months', id: uuid() },
  { label: '6 months', id: uuid() },
  { label: '12 months', id: uuid() },
];

export const formattedCurrency = (amount: number) =>
  new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(amount);

export const formatMandateDate = (
  date: string,
  formatStyle = 'MMM dd, yyyy | h:mm aaa'
) => {
  if (!date) return '';
  //   'Jan 6, 2022 | 6:30pm' // time format;
  return format(new Date(date), formatStyle);
};

export function getInitials(text: string) {
  if (!text) return '';
  const texts = text.split(' ');
  return `${texts[0]?.charAt(0)?.toUpperCase()}${texts[1]
    ?.charAt(0)
    ?.toUpperCase()}`;
}

export type Money = string | number | undefined;
export const formatCash = (_value: Money): string => {
  const value: string = `${_value}`.replace(/\s/gi, '');
  if (!value || Number.isNaN(+value)) {
    return '0';
  }
  return `${parseFloat(value).toFixed(2)}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
};

export function getDateFilter(condition: IItemProps) {
  switch (condition.label) {
    case 'Today':
      return {
        fromDate: `${moment().format('YYYY-MM-DD')} 00:00:00`,
        toDate: `${moment().format('YYYY-MM-DD')} 23:59:59`,
      };
    case 'Yesterday':
      return {
        fromDate: `${moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')} 00:00:00`,
        toDate: `${moment().subtract(1, 'days').format('YYYY-MM-DD')} 23:59:59`,
      };
    case '30 days':
      return {
        fromDate: `${moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD')} 00:00:00`,
        toDate: `${moment().format('YYYY-MM-DD')} 23:59:59`,
      };
    case '3 months':
      return {
        fromDate: `${moment()
          .subtract(3, 'months')
          .format('YYYY-MM-DD')} 00:00:00`,
        toDate: `${moment().format('YYYY-MM-DD')} 23:59:59`,
      };
    case '6 months':
      return {
        fromDate: `${moment()
          .subtract(6, 'months')
          .format('YYYY-MM-DD')} 00:00:00`,
        toDate: `${moment().format('YYYY-MM-DD')} 23:59:59`,
      };
    case '12 months':
      return {
        fromDate: `${moment()
          .subtract(12, 'months')
          .format('YYYY-MM-DD')} 00:00:00`,
        toDate: `${moment().format('YYYY-MM-DD')} 23:59:59`,
      };

    default:
      return {
        fromDate: `${moment().format('YYYY-MM-DD')} 00:00:00`,
        toDate: `${moment().format('YYYY-MM-DD')} 23:59:59`,
      };
  }
}

const handleMixedNumbers = (text: string, matcher: RegExpMatchArray | null) => {
  if (matcher && matcher.length) {
    for (const index in matcher) {
      text = text.replace(matcher[index], ` ${matcher[index]} `);
    }
    return text;
  }
  return text;
};

export const handleCamelcasing = (text: string) => {
  text = text.replace(/([a-z])([A-Z])/g, '$1 $2');
  text = text.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  text = `${text[0].toUpperCase()}${text.substring(1)}`;
  const matcher = text.match(/\d+/g);
  if (matcher) text = handleMixedNumbers(text, matcher);
  return text.trim();
};

export const base64PreferredPrefix = 'data:image/png;base64,';

export function removeBase64Prefix(
  base64String: string,
  prefix = base64PreferredPrefix
) {
  // Check if the base64String starts with the specified prefix
  if (base64String.startsWith(prefix)) {
    // Remove the prefix
    const encodedData = base64String.slice(prefix.length);

    // Decode the Base64 string
    // const decodedData = atob(encodedData);

    return encodedData;
  } else {
    // If the prefix is not present, return the original string
    return base64String;
  }
}

export const addEllipsisToText = (text: string, limit: number = 10) => {
  return text === 'null' || !text
    ? '--'
    : text.length > limit
      ? text.substring(0, limit) + '...'
      : text.substring(0);
};

export const calcDuration = (start: string, end: string) => {
  const startMoment = moment(start);
  const endMoment = moment(end);

  const years = endMoment.diff(startMoment, 'years');
  startMoment.add(years, 'years');

  const months = endMoment.diff(startMoment, 'months');
  startMoment.add(months, 'months');

  const weeks = endMoment.diff(startMoment, 'weeks');
  startMoment.add(weeks, 'weeks');

  const days = endMoment.diff(startMoment, 'days');

  let durationString = '';

  if (years) {
    durationString += `${years} Year${years > 1 ? 's' : ''} `;
  }

  if (months) {
    durationString += `${months} Month${months > 1 ? 's' : ''} `;
  }

  if (weeks) {
    durationString += `${weeks} Week${weeks > 1 ? 's' : ''} `;
  }

  if (days) {
    durationString += `${days} Day${days > 1 ? 's' : ''} `;
  }

  return durationString.trim();
};

export const frequencyInWords = (frequency: frequencyType): string => {
  if (typeof frequency !== 'string') {
    return frequencyObj[Number(frequency) as frequencyType] || '--';
  }

  if (String(frequency).length > 2) {
    return (
      Object.values(frequencyObj).find(
        (val) => val.toLowerCase() == String(frequency).toLowerCase()
      ) || '--'
    );
  }

  return frequencyObj[Number(frequency) as frequencyType] || '--';
};

export function getFrequency(val: string) {
  switch (val) {
    case 'weekly':
      return 2;
    case 'monthly':
      return 3;
    case 'daily':
      return 1;
    default:
      return 1;
  }
}

export function generateRandomString(length: number) {
  const alphanumericChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
    result += alphanumericChars.charAt(randomIndex);
  }
  return result;
}

export function updateFavIcon(logo: string) {
  const img = new Image();
  img.src = base64PreferredPrefix + logo;
  img.onload = () => {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    // Get the canvas context
    const ctx = canvas.getContext('2d');
    // Draw the image onto the canvas
    ctx?.drawImage(img, 0, 0);
    // Get the canvas data URL
    const canvasDataURL = canvas.toDataURL();
    // Set the favicon
    const favicon = document.querySelector('link[rel="icon"]');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (favicon) favicon.href = canvasDataURL;
  };
}
