import React from 'react';
import styles from './customDropdown.module.css';
import { combineClassName } from '../../utils/utilsMethods';
import { DetailedHTMLProps, HTMLAttributes, ReactNode, useState } from 'react';
import { Icon, IconProps } from '../icons';
import useClickAway from '../../hooks/useClickAway';

export interface IItemProps {
  icon?: string;
  label: string;
  id: string;
}

interface ICustomDropdownProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  items?: IItemProps[];
  defaultIcon?: string;
  showSelectedLabel?: boolean;
  selectedOption?: IItemProps;
  showIcon?: boolean;
  children?: ReactNode;
  handleSelect?: (item: IItemProps) => void;
  defaultLabel?: string;
}

export default function CustomDropdown({
  defaultIcon = 'currency',
  showSelectedLabel = false,
  showIcon = true,
  ...props
}: ICustomDropdownProps) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<IItemProps>();

  const toggleDropdown = () => {
    setOpen((prev) => !prev);
  };

  const { ref } = useClickAway<HTMLDivElement>(() => setOpen(false));

  const selectOption = (option: IItemProps) => {
    if (props.handleSelect) props.handleSelect(option);
    setSelected(option);
  };
  return (
    <div
      className={combineClassName(
        styles.custom_dropdown,
        'd-flex p-1',
        props.className ? props.className : ''
      )}
      ref={ref}
      onClick={toggleDropdown}
      style={props.style ? { ...props.style } : {}}
      data-cy={props.id}
    >
      <div className="me-2">
        {showIcon && (
          <Icon
            name={
              (selected?.icon as IconProps['name']) ||
              (defaultIcon as IconProps['name'])
            }
          />
        )}
      </div>
      {showSelectedLabel && (
        <div className="me-2" style={{ fontSize: 12 }}>
          {props.defaultLabel ?? props.defaultLabel}{' '}
          {selected?.label ||
            (props.selectedOption ? props.selectedOption.label : '')}
        </div>
      )}
      <div>
        <Icon name="caret_down" rotate={open ? 180 : 0} />
      </div>

      {open && !props.children && (
        <div className={styles.custom_dropdown_items}>
          {props.items &&
            props.items.map((item, i) => (
              <div
                key={i}
                className={combineClassName(
                  'd-flex',
                  styles.custom_dropdown_item
                )}
                onClick={() => selectOption(item)}
              >
                <div>
                  <Icon name={item.icon as IconProps['name']} />
                </div>
                <span>{item.label}</span>
              </div>
            ))}
        </div>
      )}

      {open && props.children && (
        <div>{props.children && <div>{props.children}</div>}</div>
      )}
    </div>
  );
}
