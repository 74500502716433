import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { storageKey } from '../../utils/constants';
import { getItem, removeItem, setItem } from '../../utils/storage';
import { IGetBanks } from '../../interface/mandate';

// Define a type for the slice state
interface GlobalState {
  email: string | null;
  receiverName: string | null;
  receiverAccountNo: string | null;
  receiverBank: string | null;
  billingAccountNo: string | null;
  companyLogo: string | null;
  accountBalance: string | null;
  bankList?: IGetBanks['data']['bank'];
  showBalance?: boolean;
}

const userData = getItem(storageKey.USER_INFO) as GlobalState;

// Define the initial state using that type
const initialState: GlobalState = {
  email: userData?.email ?? null,
  receiverName: userData?.receiverName ?? null,
  receiverAccountNo: userData?.receiverAccountNo ?? null,
  receiverBank: userData?.receiverBank ?? null,
  billingAccountNo: null,
  companyLogo: userData?.companyLogo ?? null,
  accountBalance: userData?.accountBalance ?? null,
  bankList: [],
  showBalance: false,
};

export const globalSlice = createSlice({
  name: 'global',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGlobalState: (state, action: PayloadAction<GlobalState>) => {
      setItem(storageKey.USER_INFO, action.payload);
      state.email = action.payload.email;
      state.receiverName = action.payload.receiverName;
      state.receiverAccountNo = action.payload.receiverAccountNo;
      state.companyLogo = action.payload.companyLogo;
      state.accountBalance = action.payload.accountBalance;
      state.billingAccountNo = action.payload.billingAccountNo;
    },
    setCompanyLogo: (state, action: PayloadAction<string>) => {
      setItem(storageKey.USER_INFO, state);
      state.companyLogo = action.payload;
    },
    setBankList: (state, action: PayloadAction<GlobalState['bankList']>) => {
      state.bankList = action.payload;
    },
    setBillingAccount: (state, action: PayloadAction<string>) => {
      state.billingAccountNo = action.payload;
    },
    setReceiverAccount: (
      state,
      action: PayloadAction<{ accountNumber: string; bankCode: string }>
    ) => {
      state.receiverAccountNo = action.payload.accountNumber;
      state.receiverBank = action.payload.bankCode;
    },
    resetGlobalState: () => {
      removeItem(storageKey.USER_INFO);
      return initialState;
    },
    setShowBalance: (state) => {
      state.showBalance = !state.showBalance;
    },
  },
});

export const {
  setGlobalState,
  setBankList,
  resetGlobalState,
  setCompanyLogo,
  setShowBalance,
  setBillingAccount,
  setReceiverAccount,
} = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGlobalState = (state: RootState) => state.global;

export default globalSlice.reducer;
