import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useToast from '../../hooks/useToast';
import { useValidation } from '../../hooks/useValidation';
import api from '../../network/api';
import {
  selectGlobalState,
  setBillingAccount,
} from '../../redux/features/globalSlice';
import { closeModal } from '../../redux/features/modalSlice';
import { createBillingAccountValidation } from '../../utils/validations';
import TextInput from '../textInput';

const CreateBillingAccountModal = () => {
  const globalState = useAppSelector(selectGlobalState);

  const dispatch = useAppDispatch();
  const toast = useToast();

  const { data, addData, validated, validationResult } = useValidation(
    createBillingAccountValidation
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (globalState?.receiverAccountNo) {
      addData('receiverAccountNumber', globalState.receiverAccountNo);
    }
  }, [globalState]);

  const createBillingAccount = async () => {
    setLoading(true);
    try {
      const creationData = {
        action: 'configureBillingAccount',
        billingAccountNumber: data.billingAccountNumber as string,
      };
      const result = await api.configureAccount(creationData);

      toast.success(result.message ?? 'Account created successfully');
      dispatch(setBillingAccount(creationData.billingAccountNumber));
      dispatch(closeModal());
    } catch (err) {
      const error = err as Error;
      toast.error(error.message ?? 'Unable to create bulling account');
    }
    setLoading(false);
  };

  function onInputChange(name: string, value: string) {
    if (name === 'amount') {
      const newValue = value.replace(/,/g, '');
      addData(name, Number(newValue ?? 0).toLocaleString());
    } else {
      addData(name, value);
    }
  }
  return (
    <form>
      <Modal.Body className="d-flex flex-column gap-2">
        <TextInput
          label="Billing Account Number"
          placeholder="Enter account number"
          id="billingAccountNumber"
          name="billingAccountNumber"
          max={10}
          type="text"
          onChange={onInputChange}
          errorText={validationResult.billingAccountNumber}
          valid={!validationResult.billingAccountNumber}
          defaultValue={(data?.billingAccountNumber as string) ?? ''}
          isRequired
        />
      </Modal.Body>
      <Modal.Footer bsPrefix="border-0 gap-3 d-flex justify-content-between align-items-center p-3">
        <Button
          className={`btn border d-flex gap-2 w-50 text-center  py-2 justify-content-center px-3 align-items-center`}
          variant="outline"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            createBillingAccount();
          }}
          disabled={loading || !validated}
          variant="primary"
          className={`btn d-flex gap-2 w-50 text-center justify-content-center py-2 px-3 align-items-center`}
        >
          Submit
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default CreateBillingAccountModal;
