import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CheckBox from '../checkbox';
import Text from '../text';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeModal } from '../../redux/features/modalSlice';
import { selectMandateState } from '../../redux/features/mandateSlice';
import api from '../../network/api';
import { formatMandateDate } from '../../utils/utilsMethods';
import { selectGlobalState } from '../../redux/features/globalSlice';
import useToast from '../../hooks/useToast';
import { MANDATE_SUCCESS } from '../../routes/navigations';
import { useNavigate } from 'react-router';
import { frequencyObj } from './EditMandateModal';

const SubmitBatchModal = () => {
  const toast = useToast();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    bulkMandate: { batchList, batchReference },
  } = useAppSelector(selectMandateState);
  const { receiverAccountNo, receiverBank } = useAppSelector(selectGlobalState);

  const {
    modalProps: { submitSelectedMandate = false },
  } = useAppSelector((state) => state.modal);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const processedMandateList = !submitSelectedMandate
        ? batchList.map((mandate) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {
              amount,
              payerAddress,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              isSelected,
              reference,
              payerBank,
              payerAccountNumber,
              ...rest
            } = mandate;
            const startDate = formatMandateDate(
              String(rest.startDate),
              'dd MMMM yyyy'
            );
            const endDate = formatMandateDate(
              String(rest.endDate),
              'dd MMMM yyyy'
            );

            return {
              ...rest,
              amount: String(amount),
              payerAddress: String(payerAddress),
              payerBank: String(payerBank),
              reference: String(reference),
              payerAccountNumber: String(payerAccountNumber),
              receiverAccountNumber: receiverAccountNo,
              receiverBank: receiverBank,
              frequency: !isNaN(rest?.frequency)
                ? rest?.frequency
                : Object.entries(frequencyObj).find(
                    ([, value]) =>
                      value.toLowerCase() ==
                      String(rest?.frequency)?.toLowerCase()
                  )?.[0],
              startDate,
              endDate,
            };
          })
        : batchList
            .filter((mandate) => mandate.isSelected)
            .map((mandate) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const {
                amount,
                payerAddress,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                isSelected,
                reference,
                payerBank,
                payerAccountNumber,
                ...rest
              } = mandate;
              const startDate = formatMandateDate(
                String(rest.startDate),
                'dd MMMM yyyy'
              );
              const endDate = formatMandateDate(
                String(rest.endDate),
                'dd MMMM yyyy'
              );

              return {
                ...rest,
                amount: String(amount),
                payerAddress: String(payerAddress),
                payerBank: String(payerBank),
                reference: String(reference),
                payerAccountNumber: String(payerAccountNumber),
                receiverAccountNumber: receiverAccountNo,
                receiverBank: receiverBank,
                frequency: !isNaN(rest?.frequency)
                  ? rest?.frequency
                  : Object.entries(frequencyObj).find(
                      ([, value]) =>
                        value.toLowerCase() ==
                        String(rest?.frequency)?.toLowerCase()
                    )?.[0],
                startDate,
                endDate,
              };
            });

      const response = await api.uploadMandateInBulk(
        processedMandateList,
        batchReference
      );
      if (response?.status === '00') {
        toast.success(response.message || '');
        navigate(MANDATE_SUCCESS);
        dispatch(closeModal());
      } else {
        toast.error(response.message || '');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Body className="d-flex flex-column align-items-start  pb-3 pt-0">
      <div className="mb-4">
        <Text
          text={'Submit Batch'}
          style={{ fontWeight: 600 }}
          size={4}
          color="var(--Gray-600, #475467)"
        />
        <Text
          text="Are you sure you want to submit this batch"
          size={1}
          color="var(--Gray-600, #475467)"
        />
      </div>
      <div>
        <CheckBox
          handleCheck={setAgreeTerms}
          aria-checked={agreeTerms}
          label="I have reviewed the information on this batch for accuracy"
          id="agreeCheckBox"
        />
      </div>

      <Modal.Footer bsPrefix="border-0 w-100 gap-3 mt-2 d-flex justify-content-between align-items-center">
        <Button
          className={`btn border d-flex w-50 text-center  py-2 justify-content-center px-3 align-items-center`}
          variant="outline"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            onSubmit();
          }}
          disabled={!agreeTerms || loading}
          variant="primary"
          className={`btn d-flex w-50 text-center justify-content-center py-2 px-3 align-items-center`}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal.Body>
  );
};

export default SubmitBatchModal;
