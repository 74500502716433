const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const WALLET_BASE_URL = process.env.REACT_APP_API_WALLET_URL;

const endpoints = {
  // auth
  AUTH: `${BASE_URL}/login`,
  // Mandate
  CREATE_MANDATE: `${BASE_URL}/mandate/setup?e-mandate=true`,
  GET_BANK: `${WALLET_BASE_URL}/bank`,
  VALIDATE_ACCOUNT: `${BASE_URL}/transfer/recipient`,
  FETCH_ALL_MANDATE: `${BASE_URL}/list`,
  FETCH_MANDATE_DETAILS: `${BASE_URL}/details`,
  MANDATE_ANALYTICS: `${BASE_URL}/analytics`,
  FETCH_MANDATE_HISTORY: `${BASE_URL}/transactions`,
  TRANSACTION_ANALYSIS: `${BASE_URL}/transactions/analytics`,
  TRIGGER_DEBIT: `${BASE_URL}/account/debit`,
  BULK_MANDATE_UPLOAD: `${BASE_URL}/mandate/bulk/setup`,
  // profile
  UPLOAD: `${BASE_URL}/upload`,
  CONFIG: `${BASE_URL}/account/config`,
};
export default endpoints;
