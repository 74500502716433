import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useToast from '../../hooks/useToast';
import { useValidation } from '../../hooks/useValidation';
import { IError } from '../../interface/error';
import { IValidateAccountResponse } from '../../interface/mandate';
import api from '../../network/api';
import {
  selectGlobalState,
  setBankList,
  setReceiverAccount,
} from '../../redux/features/globalSlice';
import { closeModal } from '../../redux/features/modalSlice';
import { createRecieverAccountValidation } from '../../utils/validations';
import CustomSelect from '../customSelect';
import TextInput from '../textInput';

const CreateReceivingAccountModal = () => {
  const globalState = useAppSelector(selectGlobalState);
  //   const { modalProps } = useAppSelector((state) => state.modal);

  const dispatch = useAppDispatch();
  const toast = useToast();

  const { data, addData, validated, validationResult, setValidationResult } =
    useValidation(createRecieverAccountValidation);

  const [accountValidated, setAccountValidated] = useState(false);
  const [validationInfo, setValidationInfo] =
    useState<IValidateAccountResponse['data']>();
  const [loading, setLoading] = useState(false);

  const bankValues = useMemo(() => {
    if (!globalState.bankList) return [];
    return [...globalState.bankList.map((bank) => bank.name)];
  }, [globalState.bankList]);

  useEffect(() => {
    if (globalState?.receiverAccountNo) {
      addData('receiverAccountNumber', globalState.receiverAccountNo);
    }
  }, [globalState]);

  async function validateAccountNumber() {
    setLoading(true);
    try {
      const response = await api.validateAccount({
        accountNo: data.receiverAccountNumber as string,
        bank: data.receiverBank as string,
        transfer_type: data.receiverBank === '999999' ? 'intra' : 'inter',
      });
      setAccountValidated(true);
      setValidationInfo(response.data);
    } catch (error) {
      validationResult.payerAccountNumber = 'xxxxxxx';
      setValidationResult((prev) => {
        return {
          ...prev,
          payerAccountNumber: err.message || 'Account validation failed',
        };
      });
      const err = error as IError;
      toast.error(err.message || '');
    }
    setLoading(false);
  }

  const createReceiverAccount = async () => {
    setLoading(true);
    try {
      const creationData = {
        action: 'configureMandateRecipient',
        receiverAccountNumber: data.receiverAccountNumber as string,
        receiverBank: data.receiverBank as string,
        receiverAccountName: validationInfo?.name,
      };

      const result = await api.configureAccount(creationData);

      dispatch(
        setReceiverAccount({
          accountNumber: creationData.receiverAccountNumber,
          bankCode: creationData.receiverBank,
        })
      );
      dispatch(closeModal());
      toast.success(result.message ?? 'Account created successfully');
    } catch (err) {
      const error = err as Error;
      toast.error(error.message ?? 'Unable to create receiver account');
    }
    setLoading(false);
  };

  function onInputChange(name: string, value: string) {
    if (name === 'amount') {
      const newValue = value.replace(/,/g, '');
      addData(name, Number(newValue ?? 0).toLocaleString());
    } else {
      addData(name, value);
    }
  }

  const handleSelect = (bank: string): void => {
    const receiverBank = globalState?.bankList?.find(
      (bankData) => bankData.name === bank
    );
    if (bank) addData('receiverBank', receiverBank?.code ?? '');
    if (bank) addData('bankName', receiverBank?.name ?? '');
  };

  async function getBankList() {
    try {
      const response = await api.getBanks();
      dispatch(setBankList(response.data.bank));
    } catch (error) {
      const err = error as IError;
      toast.error(err.message || '');
    }
  }

  useEffect(() => {
    if (!globalState?.bankList?.length) {
      getBankList();
    }
  }, []);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      if (
        data.receiverBank &&
        data.receiverAccountNumber &&
        (data.receiverAccountNumber as string)?.length === 10
      ) {
        validateAccountNumber();
      }
    }, 800);

    return () => clearTimeout(debounceFn);
  }, [data.receiverAccountNumber, data.receiverBank]);

  return (
    <form>
      <Modal.Body className="d-flex flex-column gap-2">
        <TextInput
          label="Receiver Account Number"
          placeholder="Enter account number"
          id="receiverAccountNumber"
          name="receiverAccountNumber"
          max={10}
          type="text"
          onChange={onInputChange}
          errorText={validationResult.receiverAccountNumber}
          valid={!validationResult.receiverAccountNumber}
          defaultValue={(data?.receiverAccountNumber as string) ?? ''}
          isRequired
        />
        <CustomSelect
          label="Bank Name"
          searchable
          labelDescription="Select bank"
          placeholder="Select bank"
          values={bankValues}
          onChange={handleSelect}
          selectedValue={(data.bankName as string) ?? ''}
          isRequired
          id="selectPayerBankToTrigger"
          // disabled={true}
          // hideLabel={false}
        />

        {validationInfo && (
          <TextInput
            label="Account Name"
            placeholder="Account name"
            id="receiverAcountName"
            name="receiverAccountName"
            type="text"
            defaultValue={validationInfo?.name ?? ''}
            onChange={() => console.log('')}
            disabled
          />
        )}
      </Modal.Body>
      <Modal.Footer bsPrefix="border-0 gap-3 d-flex justify-content-between align-items-center p-3">
        <Button
          className={`btn border d-flex gap-2 w-50 text-center  py-2 justify-content-center px-3 align-items-center`}
          variant="outline"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            createReceiverAccount();
          }}
          disabled={loading || !accountValidated || !validated}
          variant="primary"
          className={`btn d-flex gap-2 w-50 text-center justify-content-center py-2 px-3 align-items-center`}
        >
          Submit
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default CreateReceivingAccountModal;
