export const loginValidation = {
  email: (data: Record<string, string>): string => {
    if (!data?.email) return 'Email is required';
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.email
      )
    ) {
      return 'Invalid email';
    }
    return '';
  },
  password: (data: Record<string, string>): string => {
    if (!data?.password) return 'password is required';
    return '';
  },
};

export const createMandateValidation = {
  payerBank: (data: Record<string, string>): string => {
    if (!data?.payerBank) return 'Bank is required';
    return '';
  },
  payerAccountNumber: (data: Record<string, string>): string => {
    if (!data?.payerAccountNumber) return 'Account Number is required';
    if (data?.payerAccountNumber.length !== 10) return 'Invalid account number';
    return '';
  },
  payerAddress: (data: Record<string, string>): string => {
    if (!data?.payerAddress) return "Payer's address is required";
    return '';
  },
  payerPhone: (data: Record<string, string>): string => {
    if (!data?.payerPhone) return 'Phone is required';
    if (data.payerPhone.length !== 11)
      return 'Phone number should be 11 digits';
    return '';
  },
  payerEmailAddress: (data: Record<string, string>): string => {
    if (!data?.payerEmailAddress) return 'Payer email is required';
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.payerEmailAddress
      )
    ) {
      return 'Invalid email';
    }
    return '';
  },
  amount: (data: Record<string, string>): string => {
    if (!data?.amount) return 'Amount is required';
    if (Number(data.amount) < 1) return 'Enter a valid amount';
    return '';
  },
  startDate: (data: Record<string, string>): string => {
    if (!data?.startDate) return 'Start date is required';
    return '';
  },
  endDate: (data: Record<string, string>): string => {
    if (!data?.endDate) return 'End date is required';
    return '';
  },
  frequency: (data: Record<string, string>): string => {
    if (!data?.frequency) return 'Frequency is required';
    return '';
  },
};

export const triggerMandateValidation = {
  payerBank: (data: Record<string, string>): string => {
    if (!data?.payerBank) return 'Bank is required';
    return '';
  },
  payerAccountNumber: (data: Record<string, string>): string => {
    if (!data?.payerAccountNumber) return 'Account Number is required';
    if (data?.payerAccountNumber.length !== 10) return 'Invalid account number';
    return '';
  },
  payerAddress: (data: Record<string, string>): string => {
    if (!data?.payerAddress) return "Payer's address is required";
    return '';
  },
  narration: (data: Record<string, string>): string => {
    if (!data?.narration) return 'Narration is required';
    return '';
  },
  payerPhone: (data: Record<string, string>): string => {
    if (!data?.payerPhone) return 'Phone is required';
    if (data.payerPhone.length !== 11)
      return 'Phone number should be 11 digits';
    return '';
  },
  payerEmailAddress: (data: Record<string, string>): string => {
    if (!data?.payerEmailAddress) return 'Payer email is required';
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.payerEmailAddress
      )
    ) {
      return 'Invalid email';
    }
    return '';
  },
  amount: (data: Record<string, string>): string => {
    if (!data?.amount) return 'Amount is required';
    if (Number(data.amount) < 1) return 'Enter a valid amount';
    return '';
  },
  startDate: (data: Record<string, string>): string => {
    if (!data?.startDate) return 'Start date is required';
    return '';
  },
  endDate: (data: Record<string, string>): string => {
    if (!data?.endDate) return 'End date is required';
    return '';
  },
  frequency: (data: Record<string, string>): string => {
    if (!data?.frequency) return 'Frequency is required';
    return '';
  },
};

export const validateInput = ({
  data,
  onSubmit = true,
  validations,
}: {
  data: Record<string, unknown>;
  validations: { [key: string]: (data: Record<string, string>) => string };
  onSubmit: boolean;
}): {
  validationResultData?: { [key: string]: string };
  validated: boolean;
} => {
  const dataKeys = Object.keys(validations);
  let validationResultData: { [key: string]: string } = {};
  if (!dataKeys.length) return { validated: false };

  dataKeys.forEach((key) => {
    if (!onSubmit && !data[key]) {
      return false;
    }
    const result = validations[key](data as { [key: string]: string });
    validationResultData = { ...validationResultData, [key]: result };
  });

  const values = Object.values(validationResultData);
  const filteredValues = Object.values(validationResultData).filter(
    (val) => val
  );

  return {
    validationResultData,
    validated: filteredValues.length === 0 && values.length === dataKeys.length,
  };
};

export const createRecieverAccountValidation = {
  receiverAccountNumber: (data: Record<string, string>): string => {
    if (!data?.receiverAccountNumber) return 'Account Number is required';
    if (data?.receiverAccountNumber.length !== 10)
      return 'Invalid account number';
    return '';
  },
  receiverBank: (data: Record<string, string>): string => {
    if (!data?.receiverBank) return 'Bank is required';
    return '';
  },
};

export const createBillingAccountValidation = {
  billingAccountNumber: (data: Record<string, string>): string => {
    if (!data?.billingAccountNumber) return 'Account Number is required';
    if (data?.billingAccountNumber.length !== 10)
      return 'Invalid account number';
    return '';
  },
};
