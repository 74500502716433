import React, { useEffect, useState } from 'react';
import styles from '../../mandate.module.css';
import {
  addEllipsisToText,
  combineClassName,
  daysItem,
  getDateFilter,
} from '../../../../utils/utilsMethods';
import Card from '../../../../components/card';
import Text from '../../../../components/text';
import TextInput from '../../../../components/textInput';
import { useLocation, useParams } from 'react-router-dom';
import { IMandateTransactionHistory } from '../../../../interface/mandate';
import api from '../../../../network/api';
import Loader from '../../../../components/Loader/Loader';
import TransactionHistoryTable from './TransactionHistoryTable';
import CustomDropdown, {
  IItemProps,
} from '../../../../components/customDropdown';
import useRouteQuery from '../../../../hooks/useRouteQuery';
import useFetchMandate from '../../../../hooks/useFetchMandate';
import MandatePagination from '../../components/MandatePagination';

const BulkTransactionHistory = () => {
  const { searchParams, setURLQuery } = useRouteQuery();
  const dateLabel = searchParams.get('dateLabel');
  const searchString = searchParams.get('search');
  const { details: mandateDetailsId, mandateId } = useParams();
  const {
    filterMandate,
    handleMandateFilter,
    currentPage,
    gotoNextPage,
    gotoPage,
    gotoPreviousPage,
  } = useFetchMandate(dateLabel!);
  const [tableDetails, setDetails] = useState<
    Pick<IMandateTransactionHistory['data'], 'totalElements' | 'totalPages'>
  >({
    totalElements: 0,
    totalPages: 0,
  });
  const [transactionList, setTransactionList] = useState<
    IMandateTransactionHistory['data']['content']
  >([]);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const SINGLE_VIEW = 'singleview';

  const idToUse =
    pathname.split('/')[2] == SINGLE_VIEW ? mandateId : mandateDetailsId;

  const fetchMandateDetails = async (filterMandate: IItemProps) => {
    const filterResult = getDateFilter(filterMandate);

    try {
      setLoading(true);
      const response = await api.fetchAllMandateDetailsTransaction({
        search: searchString?.length ? searchString : idToUse,
        size: '10',
        page: `${currentPage}`,
        startDate: searchString?.length ? '' : filterResult.fromDate,
        endDate: searchString?.length ? '' : filterResult.toDate,
      });
      setTransactionList(response?.data?.content);
      setDetails({
        totalElements: response?.data?.totalElements,
        totalPages: response?.data?.totalPages,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateUrl = (dateLabel: string) => {
    const newState = { dateLabel };
    setURLQuery(newState);
  };

  useEffect(() => {
    fetchMandateDetails(filterMandate);
  }, [idToUse, filterMandate, searchString, currentPage]);

  const debounce = (delay = 1000) => {
    let timeout: string | number | NodeJS.Timeout | null | undefined = null;
    return (text: string) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        setURLQuery({ search: text });
      }, delay);
    };
  };

  const debounceFunction = debounce();

  function updateSearchField(term: string) {
    debounceFunction(term);
  }

  return (
    <Card
      className={combineClassName(
        styles.mandate_table,
        `flex-column shadow px-0`
      )}
    >
      <div className="px-4 pt-4 pb-4 d-flex w-100 flex-wrap align-items-center justify-content-between">
        <Text
          text={'All transactions - ' + addEllipsisToText(idToUse!, 12)}
          size={4}
          bold
          className="mb-2 mb-sm-0"
          color="var(--vfd-main-body-text)"
        />

        <div className="d-flex gap-md-4 gap-2 flex-wrap">
          <TextInput
            label="Email"
            placeholder="Search using reference"
            id="global-search_9999"
            name="search_9999"
            type="search"
            onChange={(_, value) => updateSearchField(value)}
            // errorText={validationResult.email}
            // valid={!validationResult.email}
            // isRequired
          />

          <CustomDropdown
            id="filterBulkTransactionBy"
            showIcon={false}
            items={daysItem}
            selectedOption={filterMandate}
            showSelectedLabel
            handleSelect={(item) => {
              updateUrl(item.label);
              handleMandateFilter(item);
            }}
            defaultLabel="Filter by:"
          />
        </div>
      </div>

      {loading ? (
        <Loader text="Loading transaction history" />
      ) : (
        <>
          <TransactionHistoryTable itemList={transactionList} />
          <MandatePagination
            currentPage={currentPage}
            totalPages={tableDetails?.totalPages}
            gotoNextPage={gotoNextPage}
            gotoPage={gotoPage}
            gotoPreviousPage={gotoPreviousPage}
          />
        </>
      )}
    </Card>
  );
};

export default BulkTransactionHistory;
