import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import Text from '../text';
import { Icon } from '../icons';
import moment from 'moment';

interface IProps {
  label: string;
  onChange: (date: Date) => void;
  isRequired?: boolean;
  defaultValue?: Date | null;
  errorText?: string;
  valid?: boolean;
  name?: string;
  id?: string;
}
const DatePickerComponent: FC<IProps> = ({
  defaultValue,
  label,
  onChange,
  isRequired,
  valid = false,
  errorText,
  name,
  id,
}) => {
  const [date, setDate] = useState<Date | null>(defaultValue || null);

  useEffect(() => {
    if (defaultValue) {
      setDate(new Date(moment(defaultValue).format('YYYY-MM-DD')));
    } else {
      setDate(null);
    }
  }, [defaultValue]);

  function handleChange(date: Date | null) {
    if (date) {
      setDate(date);
      if (onChange) onChange(date);
    }
  }

  return (
    <div>
      <div className="d-flex flex-row" style={{ marginBottom: '0.5rem' }}>
        <Text size={2} text={label} color="var(--black)" />
        {isRequired && <span style={{ color: 'var(--error)' }}>*</span>}
      </div>
      <DatePicker
        selected={date as Date}
        placeholderText="dd/mm/yyyy"
        onChange={(date) => handleChange(date)}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={15} // Number of years to display in the dropdown
        showIcon
        icon={<Icon name="calender" />}
        name={name}
        data-cy={id}
      />
      {/* <div style={{ color: 'red' }}>Dont forget to check the weather!</div>
      </DatePicker> */}

      <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
        {errorText}
      </div>
    </div>
  );
};

export default DatePickerComponent;
