import React from 'react';
import Text from '../../../components/text';
import styles from '../../Mandate/mandate.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/redux';
import { ICreateBulkMandateList } from '../../../interface/mandate';
import { Icon } from '../../../components/icons';
import {
  calcDuration,
  formatMandateDate,
  formattedCurrency,
} from '../../../utils/utilsMethods';
import MandatePagination from '../../Mandate/components/MandatePagination';
import { useAppSelector } from '../../../hooks/redux';
import {
  selectAMandateFromBulkMandateCreation,
  selectMandateState,
  setGotoBulkPage,
  setGotoNextBulkPage,
  setGotoPreviousBulkPage,
  setSelectBulkMandateToBeCreated,
} from '../../../redux/features/mandateSlice';
import useToast from '../../../hooks/useToast';
import { MAX_NUMBER_OF_FIELDS_ON_CREATE_BULK_MANDATE_LIST } from '../../../utils/constants';
import EmptyState from '../../../components/EmptyState/EmptyState';

interface IProps {
  itemList?: ICreateBulkMandateList[];
}

const BulkCreationTable = ({ itemList }: IProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const gotoNext = () => {
    dispatch(setGotoNextBulkPage());
  };

  const gotoPage = (page: number) => {
    dispatch(setGotoBulkPage(page));
  };

  const gotoPrev = () => {
    dispatch(setGotoPreviousBulkPage());
  };
  const {
    bulkMandate: { totalPages, currentPage },
  } = useAppSelector(selectMandateState);

  const gotoDetailsPage = (mandate: ICreateBulkMandateList) => {
    if (
      Object.keys(mandate).length <
      MAX_NUMBER_OF_FIELDS_ON_CREATE_BULK_MANDATE_LIST
    ) {
      toast.error('This mandate data is incomplete');
      return;
    }
    navigate(pathname + '/' + mandate?.reference, {
      state: { ...mandate },
    });
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table ">
          <thead>
            <tr className={styles.table_header}>
              <th className="ps-4" scope="col ">
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      dispatch(
                        setSelectBulkMandateToBeCreated(e.target.checked)
                      )
                    }
                    checked={itemList?.every((item) => item.isSelected)}
                    disabled={!itemList?.length}
                    data-cy="checkAllMandateToBeCreate"
                  />
                  <Text
                    text={'Ref'}
                    size={2}
                    style={{ width: 'max-content' }}
                    className=" ps-2"
                    color={'var(--vfd-main-body-text)'}
                  />
                  <span className="ps-2">
                    <Icon name="downward_arrow" />
                  </span>
                </div>
              </th>
              <th scope="col">
                <Text
                  text={'Customer Name'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className=" ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Account Number'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className=" ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Mandate Amount'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className=" ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Duration'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className=" ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Date Created'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className=" ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Actions'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className=" ps-2 invisible "
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
            </tr>
          </thead>

          {/* Table body starts here */}
          <tbody>
            {itemList?.length ? (
              itemList.map((mandate: ICreateBulkMandateList, index) => {
                const {
                  amount,
                  startDate,
                  payerAccountNumber,
                  reference,
                  isSelected,
                  payerEmailAddress,
                  endDate,
                } = mandate;
                return (
                  <tr key={index}>
                    <td className="ps-4 py-3" scope="row">
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={isSelected}
                          data-cy="checkAMandateToBeCreate"
                          onChange={() =>
                            dispatch(
                              selectAMandateFromBulkMandateCreation(reference)
                            )
                          }
                        />
                        <Text
                          text={`${reference}`}
                          size={2}
                          style={{ width: 'max-content' }}
                          className=" ps-2"
                          color={'var(--vfd-main-body-text)'}
                        />
                      </div>
                    </td>
                    <td className="py-3">
                      <Text
                        text={String(payerEmailAddress)}
                        size={2}
                        style={{ width: 'max-content' }}
                        className=" ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3">
                      {' '}
                      <Text
                        text={String(payerAccountNumber)}
                        size={2}
                        style={{ width: 'max-content' }}
                        className=" ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3">
                      {' '}
                      <Text
                        text={formattedCurrency(Number(amount))}
                        size={2}
                        style={{ width: 'max-content' }}
                        className=" ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3">
                      {' '}
                      <Text
                        text={calcDuration(startDate, endDate)}
                        size={2}
                        style={{ width: 'max-content' }}
                        className=" ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>

                    <td className="py-3">
                      <Text
                        text={formatMandateDate(startDate, 'MMM dd, yyyy')}
                        size={2}
                        style={{ width: 'max-content' }}
                        className=" ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-2">
                      {/* <Link to={pathname + '/' + reference} className="btn">
                      <Text
                        text={'View'}
                        size={2}
                        className="d-inline ps-2"
                        color={'var(--blue-600)'}
                      />
                    </Link> */}
                      <Text
                        text={'View'}
                        size={2}
                        className="btn"
                        color={'var(--blue-600)'}
                        onClick={() => gotoDetailsPage(mandate)}
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={8} style={{ padding: '20px 0' }}>
                  <EmptyState />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <MandatePagination
        totalPages={totalPages}
        currentPage={currentPage}
        gotoNextPage={gotoNext}
        gotoPage={gotoPage}
        gotoPreviousPage={gotoPrev}
      />
    </>
  );
};

export default BulkCreationTable;
