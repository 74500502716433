import React, { ChangeEvent, CSSProperties } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './styles.scss';
import { Icon } from '../icons';

export interface TextInputProps {
  label: string;
  placeholder?: string;
  id: string;
  name: string;
  onChange: (name: string, val: string) => void;
  type?: string;
  isRequired?: boolean;
  passwordScheme?: { [key: string]: (val: string) => boolean };
  style?: CSSProperties;
  errorText?: string;
  valid?: boolean;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  selectOptions?: Array<{
    label: string;
    value: unknown;
  }>;
  className?: string;
  max?: number;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder = 'Enter here',
  id,
  name,
  onChange,
  type = 'text',
  isRequired = false,
  passwordScheme,
  valid = true,
  errorText = '',
  style = {},
  value = '',
  defaultValue,
  disabled = false,
  selectOptions,
  max,
  ...props
}) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ): void => {
    onChange(name, e.target.value);
  };

  const renderInput = (): JSX.Element => {
    if (type === 'textArea') {
      return (
        <div>
          <div className="Input-with-label">
            <label htmlFor={id} className="form-label">
              {label}
              {isRequired && <span className="Required">*</span>}
            </label>
            <textarea
              onChange={handleChange}
              className="form-control"
              id={id}
              style={{
                ...style,
                ...(!valid ? { borderColor: 'var(--danger)' } : {}),
              }}
              name={name}
              placeholder={placeholder}
              required={isRequired}
              defaultValue={defaultValue}
              data-cy={id}
            ></textarea>
            <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
              {errorText}
            </div>
          </div>
        </div>
      );
    }

    if (type === 'select') {
      return (
        <div>
          <div className="Input-with-label">
            <label htmlFor={id} className="form-label">
              {label}
              {isRequired && <span className="Required">*</span>}
            </label>
            <select
              onChange={handleChange}
              className="form-select"
              aria-label="Select country"
              data-cy={id}
            >
              {!defaultValue && !value && (
                <option selected>{'Select Country'}</option>
              )}
              {value && <option selected>{value}</option>}
              {selectOptions &&
                selectOptions.length > 0 &&
                selectOptions?.map((option) => (
                  <option key={uuidv4()} value={option.value as string}>
                    {option.label}
                  </option>
                ))}
            </select>
            <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
              {errorText}
            </div>
          </div>
        </div>
      );
    }

    if (type === 'search') {
      return (
        <div>
          <div className="search_text_container">
            <input
              onChange={handleChange}
              type={'text'}
              className={`form-control ${
                props.className ? props.className : ''
              } form_control`}
              id={id}
              style={{
                ...style,
                ...(!valid ? { borderColor: 'var(--danger)' } : {}),
              }}
              name={name}
              placeholder={placeholder}
              required={isRequired}
              defaultValue={defaultValue}
              disabled={disabled}
              autoComplete="off"
              data-cy={id}
            />
            <div className="search_icon">
              <Icon name="search_icon" />
            </div>
          </div>

          <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
            {errorText}
          </div>
          {/* </div> */}
        </div>
      );
    }

    return (
      <div>
        <div className="Input-with-label">
          <label htmlFor={id} className="form-label">
            {label}
            {isRequired && <span className="Required">*</span>}
          </label>
          <input
            onChange={handleChange}
            type={type}
            className="form-control"
            id={id}
            style={{
              ...style,
              ...(!valid ? { borderColor: 'var(--danger)' } : {}),
            }}
            name={name}
            placeholder={placeholder}
            required={isRequired}
            defaultValue={defaultValue}
            value={defaultValue}
            disabled={disabled}
            max={max}
            data-cy={id}
          />
          <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
            {errorText}
          </div>
        </div>
        {type === 'password' && passwordScheme && (
          <div
            className="d-flex"
            style={{
              flexWrap: 'wrap',
              marginTop: '-24px',
              marginBottom: '24px',
            }}
          >
            {Object.keys(passwordScheme).map((key) => (
              <div className="me-2 mb-2 Scheme" key={uuidv4()}>
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    ...(passwordScheme[key](value) ? { color: '#41ce8e' } : {}),
                  }}
                >
                  {key}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return <div>{renderInput()}</div>;
};

export default TextInput;
